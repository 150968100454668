<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Diagnose & Förderung</h2>
      <p>
        Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
        wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
        anwenden zu können. Vor allem ist es dabei zentral erfassen zu können,
        in welchen Bereichen das Operationsverständnis bereits gut ausgebildet
        ist und wo eventuell Probleme vorliegen.
      </p>
      <p>
        Es ist im Unterricht häufig so, dass Kinder nicht nur bei einem
        Schwerpunkt Schwierigkeiten haben. Um nun also einige Dokumente
        detaillierter zu betrachten, bildet die folgende Aktivität Einblicke in
        drei unterschiedliche Dokumente von Kindern.
      </p>
      <p>
        Schauen Sie sich dazu zuerst ein Malquartett an, bei dem Clarissa 4
        Karten zu der Aufgabe 5 · 7 notiert hat.
      </p>
      <div style="background-color: white">
        <LernbausteinStempeln
          :id="LBSTStempeln[0].id"
          :workOrder="LBSTStempeln[0].workOrder"
          :image="LBSTStempeln[0].image"
          :audio="LBSTStempeln[0].audio"
          :elements="LBSTStempeln[0].elements"
        >
          <template v-slot:alternative-content-0>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ov/Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe01.mp4"
                type="video/mp4"
              />
            </video>
          </template>

          <template v-slot:alternative-content-1>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ov/Stempeln_Diagnose/Diagnose Clarissa_neu_Stufe02.mp4"
                type="video/mp4"
              />
            </video>
          </template>

          <template v-slot:alternative-content>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Diagnose/Diagnose Clarissa_neu.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ovi/Diagnose/Diagnose Clarissa_neu.mp4"
                type="video/mp4"
              />
            </video>
          </template>
        </LernbausteinStempeln>
      </div>

      <p>Es folgt ein weiteres Malquartett von Ninon.</p>

      <div style="background-color: white">
        <LernbausteinStempeln
          :id="LBSTStempeln[1].id"
          :workOrder="LBSTStempeln[1].workOrder"
          :image="LBSTStempeln[1].image"
          :audio="LBSTStempeln[1].audio"
          :elements="LBSTStempeln[1].elements"
        >
          <template v-slot:alternative-content-0>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ov/Stempeln_Diagnose/Diagnose Ninon_neu_Stufe01.mp4"
                type="video/mp4"
              />
            </video>
          </template>

          <template v-slot:alternative-content-1>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ov/Stempeln_Diagnose/Diagnose Ninon_neu_Stufe02.mp4"
                type="video/mp4"
              />
            </video>
          </template>

          <template v-slot:alternative-content>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Diagnose/Diagnose Ninon_neu.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/ovi/Diagnose/Diagnose Ninon_neu.mp4"
                type="video/mp4"
              />
            </video>
          </template>
        </LernbausteinStempeln>
      </div>

      <p>
        Die Unterschiede zwischen Ninons und Clarissas Malquartett sind deutlich
        erkennbar, auch wenn sie auf den ersten Blick an ähnlichen Stellen
        liegen. Beispielsweise deutet bereits die Beschreibung der Malaufgabe
        als „7 5-mal“ und „3 Sechser“ auf eine unterschiedliche Nutzung
        bedeutungsbezogener Sprache hin. So können die Kinder beim
        Vorstellungsaufbau unterstützt werden, indem möglichst bedeutungsvolle
        Sprache genutzt wird – Sechser gibt somit an, wie viele Elemente in
        einer Gruppe vorhanden sind.
      </p>

      <AppButtonCollapse>
        <div style="background-color: white">
          <LernbausteinStempeln
            :id="LBSTStempeln[2].id"
            :workOrder="LBSTStempeln[2].workOrder"
            :image="LBSTStempeln[2].image"
            :audio="LBSTStempeln[2].audio"
            :elements="LBSTStempeln[2].elements"
          >
            <template v-slot:alternative-content-0>
              <video
                preload="auto"
                controls
                width="60%"
                style="margin-left: 20%"
                @play="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @fullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @webkitfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @mozfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @msfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @ended="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @pause="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @seeking="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @enterpictureinpicture="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
                @leavepictureinpicture="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4')
                "
              >
                <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
                <source
                  src="@/assets/ov/Stempeln_Diagnose/Diagnose John_neu_Stufe01.mp4"
                  type="video/mp4"
                />
              </video>
            </template>

            <template v-slot:alternative-content-1>
              <video
                preload="auto"
                controls
                width="60%"
                style="margin-left: 20%"
                @play="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @fullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @webkitfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @mozfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @msfullscreenchange="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @ended="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @pause="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @seeking="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @enterpictureinpicture="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
                @leavepictureinpicture="
                  videoPlayerEvent($event, 'Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4')
                "
              >
                <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
                <source
                  src="@/assets/ov/Stempeln_Diagnose/Diagnose John_neu_Stufe02.mp4"
                  type="video/mp4"
                />
              </video>
            </template>

            <template v-slot:alternative-content>
              <video
                preload="auto"
                controls
                width="60%"
                style="margin-left: 20%"
                @play="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @fullscreenchange="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @webkitfullscreenchange="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @mozfullscreenchange="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @msfullscreenchange="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @ended="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @pause="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @seeking="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @enterpictureinpicture="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
                @leavepictureinpicture="
                  videoPlayerEvent($event, 'Diagnose/Diagnose John_neu.mp4')
                "
              >
                <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
                <source
                  src="@/assets/ovi/Diagnose/Diagnose John_neu.mp4"
                  type="video/mp4"
                />
              </video>
            </template>
          </LernbausteinStempeln>
        </div>
      </AppButtonCollapse>
      <p>
        Es zeigt sich: Sowohl die Kompetenzen, die Fehler, als auch die Ursachen
        für diese Fehler können sehr unterschiedlich sein. Insgesamt ist es
        wichtig, dass die Lehrkraft die verschiedenen Facetten des umfassenden
        Operationsverständnisses kennt und diese aktiv in den Unterricht
        einbaut, sodass die Schülerinnen und Schüler möglichst zahlreiche und
        umfassende Möglichkeiten zum Ausbau dieser (Teil-)Kompetenzen erhalten.
        Es kann beispielsweise Kinder geben, die die Malaufgaben bereits
        automatisiert haben und die Rechnungen richtig notieren, doch keine
        eigene Rechengeschichte angeben können, die Rückschlüsse auf eine
        Grundvorstellung gibt. Um dies zu diagnostizieren – und dann
        anschließend zu fördern oder zu fordern – ist es essentiell, dass auch
        solche Aufgaben, die nach der Grundvorstellung fragen, gestellt werden,
        um dies zum einen aufzudecken und dem zum anderen entgegenzuwirken.
      </p>
      <p>
        Dabei ist die Aufgabenauswahl, die die Lehrkraft trifft ein zentraler
        Punkt, um alle Facetten abzudecken. Dazu sollen die folgenden Aufgaben
        danach gruppiert werden, welchen der drei Hintergrundbereiche –
        Grundvorstellungen, Darstellungswechsel und Aufgabenbeziehungen – sie
        fokussieren. Sicherlich schwingen einige Teilbereiche eines umfassenden
        Operationsverständnis teilweise auch in andere Bereiche mit. So ist der
        Darstellungswechsel beispielsweise auch gegeben, wenn die Kinder zu
        einer Aufgabe eine Rechengeschichte formulieren sollen, anhand deren man
        überprüfen kann, welche Grundvorstellung (bereits) ausgebildet ist. Für
        die folgende Aufgabe soll also geschaut werden, welcher Aspekt durch die
        Aufgabe im Fokus steht.
      </p>
      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[0].id"
          :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[0].showGroupNames"
          :workOrder="LBSTGruppen[0].workOrder"
          :possibleCategories="LBSTGruppen[0].possibleCategories"
          :elements="LBSTGruppen[0].elements"
          :responses="LBSTGruppen[0].responses"
        >
          <template v-slot:alternative-content>
            <p>
              Im Folgenden werden je drei Aufgaben vorgestellt, die die drei
              Bereiche des Operationsverständnisses – Grundvorstellungen,
              Darstellungswechsel und Aufgabenbeziehungen – hauptaugenmerklich
              ansprechen. Dabei wird vor allem der Darstellungswechsel in fast
              allen Aufgaben zumindest implizit angesprochen, da die Kinder
              beispielsweise zwischen Rechengeschichte und Mathesprache – sprich
              Aufgabe – wechseln. Eine solche Aufgabe kann eingesetzt werden, um
              zu überprüfen, inwiefern die Schülerinnen und Schüler über die
              Grundvorstellung in der Rechengeschichte verfügen, aber dazu
              müssen sie eben auch zwangsläufig zwischen zwei Darstellungsweisen
              wechseln. Die folgenden beiden fokussieren aber eher die
              Grundvorstellungen:
            </p>

            <v-row justify="center" class="standAloneCardImages">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_niklas.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_niklas.png"
                />
              </v-col>
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_claas.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_claas.png"
                />
              </v-col>
            </v-row>

            <p>
              Aber auch Aufgaben, die Kinder dazu anregen Malaufgaben in ihrer
              Umwelt zu fotografieren fokussieren meistens die
              Grundvorstellungen. Es wird dadurch deutlich, welche
              Grundvorstellungen die Kinder bereits aufgebaut haben. Sicherlich
              vor allem die Grundvorstellung des Zusammenfassens lässt sich
              häufig in der Umwelt finden und auf Bildern festhalten. Dennoch
              ist eine solche Aufgabe als Überprüfung aller Grundvorstellungen
              sinnvoll.
            </p>

            <v-row justify="center" class="standAloneCardImages">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="
                    require('@/assets/ovi/Diagnose/diagnose_fotokartei.png')
                  "
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_fotokartei.png"
                />
              </v-col>
            </v-row>

            <p>
              Steht der Darstellungswechsel mehr im Fokus, sehen die Aufgaben
              anders aus. Die beiden nachfolgenden Aufgaben bilden
              unterschiedliche Darstellungsformen ab, den Zahlenstrahl und die
              Plättchendarstellung. Dabei sind solche Darstellungen sowohl
              Lernstoff als auch Lernhilfe. Lernstoff sind sie insofern, als
              dass sie nicht selbsterklärend sind und gemeinsam mit den Kindern
              erarbeitet werden müssen.
            </p>

            <v-row justify="center" class="standAloneCardImages">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="
                    require('@/assets/ovi/Diagnose/diagnose_zahlenstrahl.png')
                  "
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_zahlenstrahl.png"
                />
              </v-col>
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_3mal5.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_3mal5.png"
                />
              </v-col>
            </v-row>

            <p>
              Auch diese Aufgabe zeigt, dass hier der Wechsel der
              Darstellungsform im Fokus steht und viele unterschiedliche
              Darstellungen zu einer Aufgabe miteinander verknüpft werden
              sollen. Sicherlich wird vor allem auch im Aufgabenteil a) die
              Grundvorstellung deutlich, die das Kind nutzt, aber durch die
              Verknüpfung mehrerer Darstellungsformen zu derselben Aufgabe,
              steht eben eher der Darstellungswechsel im Fokus.
            </p>

            <v-row justify="center" class="standAloneCardImages">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_2mal7.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_2mal7.png"
                />
              </v-col>
            </v-row>

            <p>
              Aber auch die Aufgabenbeziehungen können durch Aufgaben fokussiert
              und somit unterstützt werden. Die Aufgaben regen Kinder dazu an,
              Muster und Strukturen zu erkennen, zu beschreiben und diese zu
              begründen, um die Beziehung dann nutzen zu können. Dies ist zum
              beziehungsreichen Üben der Multiplikation zentral.
            </p>

            <v-row justify="center">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_4mal6.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_4mal6.png"
                />
              </v-col>
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_2mal9.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_2mal9.png"
                />
              </v-col>
            </v-row>

            <v-row justify="center" class="standAloneCardImages">
              <v-col :cols="standAloneCardImagesFormat">
                <AppExpandableImage
                  :src="require('@/assets/ovi/Diagnose/diagnose_suna.png')"
                  contain
                  parentId="OVI"
                  elementId="Diagnose/diagnose_suna.png"
                />
              </v-col>
            </v-row>
          </template>
        </LernbausteinGruppen>
      </div>
      <p>
        Nur mit dem Wissen was zu einen umfassenden Operationsverständnis zählt,
        kann eine themengerechte Diagnose und Förderung erstellt werden.
      </p>
      <p>
        Im nachfolgenden Beispiel haben Sie nun die Gelegenheit zunächst eine
        Feindiagnostik auf Grundlage erster diagnostischer Erkenntnisse
        durchzuführen und im Anschluss daran eine geeignete Förderaufgabe
        auszuwählen.
      </p>

      <div style="background-color: white">
        <LernbausteinPfade
          :id="LBSTPfadeBeispiele[0].id"
          :workOrder="LBSTPfadeBeispiele[0].workOrder"
          :image="LBSTPfadeBeispiele[0].image"
          :imageSizePercent="LBSTPfadeBeispiele[0].imageSizePercent"
          :mediaDescription="LBSTPfadeBeispiele[0].mediaDescription"
          :audio="LBSTPfadeBeispiele[0].audio"
          :elements="LBSTPfadeBeispiele[0].elements"
          :responses="LBSTPfadeBeispiele[0].responses"
          :pupilName="LBSTPfadeBeispiele[0].pupilName"
        />
      </div>

      <br />
      <AppLiteraturOV />
    </v-card>
    <AppBottomNavOV
      back="/operationsverstaendnis/uebergang"
      next="/operationsverstaendnis/check"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppLiteraturOV from "@/common/AppLiteraturOV";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import AppBottomNavOV from "@/common/AppBottomNavOV";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_Diagnose from "@/components/faledia/seiten/ove/beispiele/Gruppen_Diagnose";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinStempeln_Einzeldiagnose_OGVD_2 from "@/components/faledia/seiten/ove/beispiele/Einzeldiagnose_OGVD_2";
import LernbausteinStempeln_Einzeldiagnose_ODWD_2 from "@/components/faledia/seiten/ove/beispiele/Einzeldiagnose_ODWD_2";
import LernbausteinStempeln_Einzeldiagnose_OABD_2 from "@/components/faledia/seiten/ove/beispiele/Einzeldiagnose_OABD_2";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";
import LernbausteinPfade_Pfade_Fynn from "@/components/faledia/seiten/operationsverstaendnis/beispiele/PfadeFynn";

export default {
  components: {
    AppButtonCollapse,
    AppBottomNavOV,
    AppExpandableImage,
    LernbausteinGruppen,
    LernbausteinStempeln,
    AppLiteraturOV,
    LernbausteinPfade,
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_Diagnose],
    LBSTStempeln: [
      LernbausteinStempeln_Einzeldiagnose_OGVD_2,
      LernbausteinStempeln_Einzeldiagnose_ODWD_2,
      LernbausteinStempeln_Einzeldiagnose_OABD_2,
    ],
    LBSTPfadeBeispiele: [LernbausteinPfade_Pfade_Fynn],
  }),
  computed: {
    standAloneCardImagesFormat() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        return 6;
      } else {
        return 5;
      }
    },
  },
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style scoped>
.standAloneCardImages {
  margin-bottom: 12px;
}
</style>
