function createObject() {
    let example = {
        id: 'PfadeFynn',
        workOrder: `
            Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.
            `,
        image: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_1.png"),
        imageSizePercent: 60,
        mediaDescription: 'Fynn bearbeitet die folgenden beiden Aufgaben:',
        pupilName: 'Fynn',
        elements: [
            [
                // das ist der diagnose bereich, hier wählt man 2 von 4 aus
                {
                    selectCount: 2,
                    typeText: "Diagnoseaufgabe",
                    question: "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
                    diagnostics: [{
                            id: 1,
                            // taskText: 'Diagnoseaufgabe 1',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_2.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 1',
                            solutionImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_3.png"),
                        },
                        {
                            id: 2,
                            // taskText: 'Diagnoseaufgabe 2',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_4.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 2',
                            solutionImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_5.png"),
                        },
                        {
                            id: 3,
                            // taskText: 'Diagnoseaufgabe 3',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_6.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 3',
                            solutionImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_7.png"),
                        },
                        {
                            id: 4,
                            // taskText: 'Diagnoseaufgabe 4',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_8.png"),
                            // solutionText: 'Schüler:innen Lösung Diagnoseaufgabe 4',
                            solutionImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_9.png"),
                        }
                    ]
                }
            ],
            [
                // this is stage 2 (föderaufgabe)
                {
                    selectCount: 1,
                    typeText: "Förderaufgabe",
                    question: "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
                    diagnostics: [{
                            id: 1,
                            // taskText: 'Förderaufgabe 1',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_10.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 1',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 2,
                            // taskText: 'Förderaufgabe 2',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_11.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 2',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 3,
                            // taskText: 'Förderaufgabe 3',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_12.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 3',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        },
                        {
                            id: 4,
                            // taskText: 'Förderaufgabe 4',
                            taskImage: require("@/assets/ov/Pfade_Fynn/Pfade_Fynn_13.png"),
                            // solutionText: 'Schüler:innen Lösung Förderaufgabe 4',
                            // solutionImage: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
                        }
                    ]
                },
            ],
        ]
    }

    const DA_1 = `
        Diagnoseaufgabe 1 zeigt, dass Fynn noch keine räumlich simultane Vorstellung von der Aufgabe 7•8 besitzt.
        Er kennt aber das Ergebnis und zeichnet dieses richtig in die Hundertertafel ein, ohne die multiplikative Struktur der sieben Achter zu berücksichtigen.
        <br />
        `
    const DA_2 = `
        Diagnoseaufgabe 2 lässt erkennen, dass die Aufgabe 7•8 noch nicht zeitlich - sukzessiv in einen Zahlenstrahl eingezeichnet werden kann.
        Fynn kennt aber das Ergebnis der Aufgabe und zeichnet dieses richtig in den Zahlenstrahl ein, ohne die multiplikative Struktur der sieben Bögen der Länge 8 zu berücksichtigen.
        <br />
        `

    const DA_3 = `
        Diagnoseaufgabe 3 zeigt, dass Fynn ausschließlich das Ergebnis der Multiplikationsaufgabe fokussiert, welches er bereits kennt.
        Er zeigt jedoch keine Operationsvorstellung oder Grundvorstellung zur Aufgabe 5•6.
        <br /> 
        `
    const DA_4 = `
        Diagnoseaufgabe 4 lässt erkennen, dass Fynn das Ergebnis der Aufgabe fokussiert.
        Hierbei wird die multiplikative Struktur der vier Dreier nicht unbedingt als zentral wahrgenommen, da er auch die Anordnung von 10 + 2 als richtig ansieht.
        Er fokussiert nicht Multiplikator und Multiplikand.
        <br />
        `

    const FA_1 = `
        Förderaufgabe 1 zielt hingegen lediglich darauf ab, dass Fynn die Ergebnisse der Aufgaben notiert.
        Die Diagnoseaufgaben zeigen, dass er das Ergebnis kennt und häufig in den Vordergrund stellt– das sollte nicht weiter so gefördert werden.
        Zudem bietet eine solche Aufgabe kaum Potenzial zu Entdeckungen, Erläuterungen oder Gesprächen.
        `

    const FA_2_Star = `
        Förderaufgabe 2 versucht beide Grundvorstellungen zu fördern.
        Sowohl die dynamische Vorstellung des erneuten Hinzufügens Gruppen gleicher Größe als auch das Endprodukt in der flächigen Darstellung werden thematisiert.
        Es werden also beide Bereiche der Diagnose abgedeckt.
        `
    const FA_2 = `
        Förderaufgabe 2 versucht zwei Grundvorstellungen zu fördern.
        Sowohl die dynamische Vorstellung des erneuten Hinzufügens Gruppen gleicher Größe als auch das Endprodukt in der flächigen Darstellung werden thematisiert.
        `

    const FA_3 = `
        Förderaufgabe 3 versucht Fynn die Grundvorstellung des Vergleichens näher zu bringen.
        Keine Diagnoseaufgabe hat überprüft, ob er diese Vorstellung bereits besitzt.
        Es würde also passgenauere Förderaufgaben zu den ausgewählten Diagnoseaufgaben und deren Lösungen geben.
        Schauen Sie sich das noch einmal an.
        `
    const FA_3_Star = `
        Förderaufgabe 3 versucht Fynn die Grundvorstellung des Vergleichens näher zu bringen.
        Keine Diagnoseaufgabe hat überprüft, ob er diese Vorstellung bereits besitzt.
        Vielleicht hilft sie bei der Erstellung einer Rechengeschichte.
        Der Darstellungswechsel zum Zahlenstrahl oder einer Plättchendarstellung sollte separat gefördert werden.
        `
    const FA_4 = `
        Förderaufgabe 4 soll Fynn die Gruppendarstellung in Kombination mit der bedeutungsbezogenen Gruppensprache näherbringen.
        Dies fördert den Aufbau der Grundvorstellung Zusammenfassen und spiegelt somit einen Teil der gewählten Diagnoseaufgaben wieder.
        `
    const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `
    const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `
    const PASSUNG_SCHLECHT = `
        Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
        <br />
        `

    const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `

    example.responses = [{
            id: '112',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_1
        },
        {
            id: '113',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_1
        },
        {
            id: '114',
            text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_1
        },
        {
            id: '123',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_1
        },
        {
            id: '124',
            text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_1
        },
        {
            id: '134',
            text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_1
        },

        {
            id: '212',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_2_Star + " Inwiefern er das in die Darstellung des Zahlenstrahls übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '213',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_2 + " Inwiefern er das auf das Erstellen einer Rechengeschichte übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '214',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_2_Star + " Der Fokus sollte durch die Erklärung vom Ergebnis zu allen Elementen der Multiplikationsaufgabe gelenkt werden."
        },
        {
            id: '223',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_2 + " Inwiefern er das in die Darstellung des Zahlenstrahls oder das Erstellen einer Rechengeschichte übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '224',
            text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_2_Star + " Inwiefern er das in die Darstellung des Zahlenstrahls übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '234',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_2 + " Inwiefern er das auf das Erstellen einer Rechengeschichte übertragen kann, muss anschließend überprüft werden."
        },

        {
            id: '312',
            text: PASSUNG_SCHLECHT + DA_1 + DA_2 + FA_3
        },
        {
            id: '313',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_3_Star
        },
        {
            id: '314',
            text: PASSUNG_SCHLECHT + DA_1 + DA_4 + FA_3
        },
        {
            id: '323',
            text: PASSUNG_GUT + DA_2 + DA_3 + FA_3_Star
        },
        {
            id: '324',
            text: PASSUNG_SCHLECHT + DA_2 + DA_4 + FA_3
        },
        {
            id: '334',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_3_Star
        },

        {
            id: '412',
            text: PASSUNG_GUT + DA_1 + DA_2 + FA_4 + " Inwiefern er das in die Darstellung des Zahlenstrahls übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '413',
            text: PASSUNG_GUT + DA_1 + DA_3 + FA_4 + " Inwiefern er das auf das Erstellen einer Rechengeschichte übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '414',
            text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + " Förderaufgabe 4 soll Fynn die Gruppendarstellung in Kombination mit der bedeutungsbezogenen Gruppensprache näherbringen. Beide Diagnoseaufgaben zeigen, dass ihm vor allem das Denken in Gruppen schwerfällt, da er in den Diagnoseaufgaben ausschließlich das Ergebnis fokussiert. Hier setzt die Förderung passgenau an."
        },
        {
            id: '423',
            text: PASSUNG_SCHLECHT + DA_2 + DA_3 + " Die Förderaufgabe 4 fokussiert die Darstellungen und Sprache der gleichgroßen Gruppen. Keine der beiden gewählten Diagnoseaufgaben zeigt, ob Fynn das bereits beherrscht."
        },
        {
            id: '424',
            text: PASSUNG_GUT + DA_2 + DA_4 + FA_4 + " Inwiefern er das in die Darstellung des Zahlenstrahls übertragen kann, muss anschließend überprüft werden."
        },
        {
            id: '434',
            text: PASSUNG_GUT + DA_3 + DA_4 + FA_4 + " Inwiefern er das auf das Erstellen einer Rechengeschichte übertragen kann, muss anschließend überprüft werden."
        },

    ]

    return example;
}

export default createObject();